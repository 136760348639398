<template>
    <div class="select_store">
        <el-dialog :model-value="lanModalShow" :title="L['设置消息接收语言']" width="600px"  @close="close" >
            <div class="select_center">
                <div class="select_top">
                    <div class="lan_tit">{{ L['接收语言']}}：</div>
                    <template v-if="lanList.data.length > 0">
                        <el-select v-model="lan" @change="lanChange" size="small" class="m-2 select_item" :placeholder="L['请选择接收语言']">
                            <el-option v-for="(item, index) in (lanList.data || [])" :key="index" :label="item.languageName" :value="item.languageTag" />
                        </el-select>
                    </template>
                </div>
                <div class="select_store_list" :style="curLang == 'en' ? 'margin-left: 145px':''">
                    <p>{{ L['选择接收语言后，消息通知将以该种语言推送']}}</p>
                    <p>{{ L['更改接收语言，可前往个人中心-->消息-->接收设置进行调整']}}</p>
                </div>
            </div>
            <template #footer>
                    <el-button class="footer_btn2" type="primary" @click="close">
                        {{ L['取消']}}
                    </el-button>
                    <el-button class="footer_btn" type="primary" @click="choose">
                        {{ L['保存']}}
                    </el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { reactive, ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'

export default {
    name: 'index',
    components: {
    },
    setup(props,{ emit }) {
        const { proxy } = getCurrentInstance()
        const L = proxy.$getCurLanguage();
        const store = useStore()
        const curLang = localStorage.getItem('curLang') ? localStorage.getItem('curLang') : 'zh'; //默认中文
        const lanList = reactive({data:[]})
        const lan = ref('')
        const lanModalShow = ref(false)

        const lanChange = (val) => {
            lan.value = val
        }
        const choose = () => {
            let currentLanguage = lan.value;
            if(!lan.value){
                currentLanguage = lanList.data[0] ? lanList.data[0].languageTag : 'zh';
            }
            proxy.$post('v3/member/front/member/updateInfo', { currentLanguage }).then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                    lanModalShow.value = false;
                    proxy.$getUserInfo();
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        const close = () => { // 取消默认设置第一位语言
            lan.value = '';
            lanModalShow.value = false;
            let currentLanguage = lanList.data[0] ? lanList.data[0].languageTag : 'zh';
            proxy.$post('v3/member/front/member/updateInfo', { currentLanguage }).then(res => {
                if (res.state == 200) {
                    proxy.$getUserInfo();
                }
            })
        }
        onMounted(() => {
            if(!store.state.memberInfo.currentLanguage && store.state.loginFlag){
                lanModalShow.value = true;
                proxy
                .$get('v3/Language/common/list')
                .then(res => {
                    if (res.state == 200) {
                        lanList.data = res.data;
                    }
                })
            }
        })

        return {
            lanModalShow,
            L,
            curLang,
            lanList,
            close,
            lanChange,
            lan,
            choose,
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
    background-color: transparent;
    margin: 30vh auto;
    .el-dialog__headerbtn{
       top: 14px;
    }
    .el-dialog__close{
        color: #fff;
        font-size: 18px;
    }
    .el-dialog__title {
        font-size: 16px !important;
        color: #fff !important;
    }
    .el-dialog__header {
        padding: 9px 12px;
        background-color: #FF6A22;
        border-radius: 4px 4px 0px 0px;
    }
    .el-dialog__body {
        background-color: #fff;
    }
    .el-dialog__footer {
        background-color: #fff;
        padding: 20px;
    }
    .el-input__inner {
        border-radius: 0px !important;
    }
    .footer_btn2 {
        width: 90px;
        // height: 30px;
        border: 1px solid #BBBBBB;;
        background-color: #fff;
        color: #101010;
        font-size: 14px;
        min-height: 30px !important;
        padding: 0 !important;
    }
    .footer_btn {
        width: 90px;
        // height: 30px;
        border: 1px solid #FF6A22;;
        background-color: #FF6A22;
        color: #fff;
        font-size: 14px;
        min-height: 30px !important;
        padding: 0 !important;
    }
}
.select_center {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    .select_top {
        width: 462px;
        display: flex;
        align-items: center;
        .select_item {
            width: 329px !important;
            height: 32px !important;
            line-height: 32px !important;
           ::v-deep .el-input__inner{
                color: #415058;
                &::placeholder{
                    color: #BEBEBE;
                }
            }
            
        }
        .line {
            margin: 0 5px;
        }
        .lan_tit {
            color: #101010;
            white-space: nowrap;
        }
    }

    .select_store_list {
        margin-left: 70px;
        width: 329px;
        p{
            margin-top: 6px;
            font-size: 12px;
            color: #101010;
        }
    }
}
</style>
  