// 头部登陆注册导航栏
<template>
  <div class="header_wrap">
    <div class="header">
      <div class="header_left">
        <ul>
          <li :style="curLang == 'en' ? 'padding: 0 10px;':''">
            <div class="has_more li_item">
              <img class="lan_flag" :src="curFlag" alt="">
                  {{ curShowLang }}
               <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                                             @click.stop="changeLanguage(item.languageTag)" v-for="(item,index) in lanList" :key="index">{{ item.languageName }}</a>
                </div>
            </div>                                         
          </li>
        </ul>
        <span class="hello">{{ L['您好，欢迎来到'] }}{{ configInfo.basic_site_name }}</span>
        <template v-if="loginFlag">
          <span class="register h1" @click="goToByPush('/member/index')">{{
            memberInfo.memberNickName ||
              memberInfo.memberName
          }}</span>
          <span class="register h1 login_out" @click="loginOut()">[{{ L['退出'] }}]</span>
        </template>

        <template v-if="!loginFlag">
          <span class="register h1" @click="goToByPush('/login')">{{ L['登录'] }}</span>
          <span class="register h1" @click="goToByPush('/register')">{{ L['注册'] }}</span>
        </template>
      </div>
      <div class="header_right">
        <ul>
          <li @click="goToByPush('/index', {})">
            <div class="li_item">{{ L['商城首页'] }}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的订单'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list', { 'orderState': 10 })">{{ L['待支付订单'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/list', { 'orderState': 30 })">{{ L['待收货订单'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/order/evaluation')">{{ L['待评价订单'] }}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/member/index')">
            <div class="li_item">{{ L['个人中心'] }}</div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的收藏'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/collect')">{{
                  L['商品收藏']
                }}</a>
                <a href="javascript:void(0)" class="li_item_more_item"
                  @click="goToByPush('/member/collect', { type: 'store' })">{{ L['店铺收藏'] }}</a>
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/footprint')">{{
                  L['我的足迹']
                }}</a>
              </div>
            </div>
          </li>
          <li>
            <div class="has_more li_item">
              {{ L['我的账户'] }}
              <div class="li_item_more">
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/coupon')">{{
                  L['我的优惠券']
                }}</a>
                <a href="javascript:void(0)" class="li_item_more_item" @click="goToByPush('/member/balance')">{{
                  L['我的余额']
                }}</a>
              </div>
            </div>
          </li>
          <li @click="goToByPush('/service', { vid: 0 })">
            <div class="li_item">{{ L['官方客服'] }}</div>
          </li>
          <li @click="goToByPush('/article')">
            <div class="li_item">{{ L['服务中心'] }}</div>
          </li>
        </ul>
      </div>
    </div>
  <!-- 首页选择弹窗 start -->
  <SelectLang />
  <!-- 首页选择弹窗 end -->
  </div>
</template>

<script>
import SelectLang from './SelectLang'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, onMounted, ref } from "vue";
export default {
  name: "NavTopBar",
  components: {
    SelectLang
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const L = proxy.$getCurLanguage();
    const memberInfo = store.state.memberInfo;
    const loginFlag = store.state.loginFlag;
    const router = useRouter();
    const configInfo = store.state.configInfo
    const lanList = ref([])

    onMounted(() => {
      proxy
        .$get('v3/Language/common/list')
            .then(res => {
                if (res.state == 200) {
                    const langResList = res.data;
                    langResList.forEach(lang =>{
                      const tag = lang.languageTag;
                      const localItem = langTotalList.find(item => item.val == tag)
                      if(localItem) lang.img = localItem.img;
                    })
                    lanList.value = langResList;
                }
            })
    })
    const curLang = localStorage.getItem('curLang') ? localStorage.getItem('curLang') : 'zh'; //默认中文
    const curShowLang = ref(localStorage.getItem('curLang') == 'en' ? 'English' : '简体中文')
    const curFlag =  ref(localStorage.getItem('curLang') == 'en' ? require('@/assets/flag/English.png') : require('@/assets/flag/China.png'))
    const langTotalList = localStorage.getItem('langTotalList') ? JSON.parse(localStorage.getItem('langTotalList')) : [];

    const changeLanguage = (lang) => {
      if(lang == 'zh' || lang == 'en'){
        localStorage.setItem('curLang',lang);
        window.location.reload();
      } else {
        curShowLang.value = lanList.value.find(item => item.languageTag == lang).languageName;
        curFlag.value = lanList.value.find(item => item.languageTag == lang).img;
      }
    }
    //去注册
    const goRegister = () => {
      router.push({
        path: `/register`,
        query: {
          redirect: encodeURIComponent(window.location.href)
        }
      });
    };

    //前往具体的页面，type为具体的页面地址，param为参数对象
    const goToByPush = (type, param = {}) => {

      if (type == '/service') {
        let chatInfo = {
          storeId: 0,
          vendorAvatar: configInfo.platform_customer_service_logo,
          storeName: configInfo.platform_customer_service_name,
          showData: {},
          source: 'pc商城'
        }
        store.commit('saveChatBaseInfo', chatInfo)

        let newWin = router.resolve({
          path: type,
          query: param
        })

        window.open(newWin.href, '_blank')
        return
      }

      router.push({
        path: type,
        query: param
      });
    };
    //退出登录
    const loginOut = () => {
      store.commit("clearAllData"); //清除数据
      store.commit('clearCartStorage')
      proxy.$socket.disconnect()
      goToByPush("/login", {});
    };
    return { curFlag, lanList, L, goRegister, memberInfo, loginOut, loginFlag, goToByPush, configInfo, curLang, curShowLang, changeLanguage };
  }
};
</script>

<style lang="scss" scoped>
.header_wrap {
  width: 100%;
  height: 33px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    width: 1210px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header_left {
      height: 100%;
      font-size: 12px;
      color: #999;
      line-height: 36px;
      display: flex;

      .hello {
        margin-right: 20px;
        color: #999;
      }

      .h1 {
        margin: 0 5px;
        cursor: pointer;

        &:hover {
          color: $colorMain;
        }
      }
      ul {
        margin-right: 15px;
      }
    }
    ul {
        height: 100%;
        display: flex;
        align-items: center;

        .personal_center {
          width: 121px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        li {
          float: left;
          text-align: center;
          border-right: 1px solid #ddd;
          padding: 0 10px;
          height: 12px;

          .lan_flag{
            width: 24px;
            height: 16px;
            margin-right: 6px;
            vertical-align: middle;
            position: relative;
            bottom: 2px;
          }

          .li_item {
            position: relative;
            cursor: pointer;
            font-size: 12px;
            line-height: 12px;
            color: #999;
            height: 33px;

            &:hover {
              color: $colorMain;

              &.has_more {
                &:before {
                  border-top-color: $colorMain;
                }
              }

              .li_item_more {
                display: block;
              }
            }

            &.has_more {
              padding-right: 12px;

              &:before,
              &:after {
                position: absolute;
                right: -2px;
                display: block;
                width: 0;
                height: 0;
                content: " ";
                border: 4px solid transparent;
                border-radius: 2px;
              }

              &:before {
                top: 3px;
                border-top: 5px solid #888;
              }

              &:after {
                top: 1px;
                border-top: 5px solid #f7f7f7;
              }
            }

            .li_item_more {
              position: absolute;
              padding: 10px;
              left: 50%;
              transform: translateX(-50%);
              top: 21px;
              background: #fff;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
              z-index: 999;
              display: none;
              white-space: nowrap;

              &:before,
              &:after {
                position: absolute;
                top: -11px;
                left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                display: block;
                content: " ";
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-bottom: 6px solid #dedede;
              }

              &:after {
                top: -10px;
                border-bottom: 6px solid #fff;
              }

              .li_item_more_item {
                display: block;
                text-align: center;
                color: #999;
                line-height: 12px;
                padding: 8px 0;
                text-align: left;
                &:hover {
                  color: $colorMain;
                }
              }
            }
          }

          &:last-child {
            border-right-width: 0;
            padding-right: 0;
          }
        }
      }
    .header_right {
      height: 100%;
      ul {
        width: 100%;
      }
    }
  }
}
</style>